import React, { createContext, useContext } from "react";
import Client from "@models/client";

const client = new Client();

const ClientContext = createContext(client);

const ClientProvider = (props: any) => {
    return (
        <ClientContext.Provider value={client}>
            {props.children}
        </ClientContext.Provider>
    )
}

export default ClientProvider;


const useClient = () : Client => {
    return useContext(ClientContext);
}

export { useClient };


const getClient = () => client;

export { getClient };
