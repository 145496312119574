import React, { createContext, useContext } from "react";

import "katex/dist/katex.min.css";

import SubtitleManager from "@models/subtitlemanager";
import { useForceRerender } from "@utils/component";

let manager: SubtitleManager = null;

let setter = (v: SubtitleManager) => {
    manager = v;
};

const SubtitleManagerContext = createContext<[SubtitleManager, Function]>([ manager, setter ]);


const SubtitleManagerProvider = ({ children } : any) => {
    const [ forceRerender ] = useForceRerender();

    setter = (v: SubtitleManager) => {
        manager = v;
        forceRerender();
    }

    return (
        <SubtitleManagerContext.Provider value={[ manager, setter ]}>
            { children }
        </SubtitleManagerContext.Provider>
    );
}

export default SubtitleManagerProvider;


const useSubtitleManager = (): [SubtitleManager, Function] => {
    return useContext(SubtitleManagerContext);
}

export { useSubtitleManager };


const getSubtitleManager = (): [SubtitleManager, Function] => [manager, setter];

export { getSubtitleManager };
