import React, { ReactNode, useLayoutEffect, useState } from "react";
import { Router } from "react-router-dom";
import { History } from "history";

interface BrowserRouterProps {
    children: ReactNode;
    history: History;
    basename?: string;
};

const HistoryRouter = ({ children, history, basename }: BrowserRouterProps) => {
    let [state, setState] = useState({
        action:   history.action,
        location: history.location
    });

    useLayoutEffect(() => history.listen(setState), [ history ]);

    return (
        <Router
            basename={basename}
            children={children}
            location={state.location}
            navigationType={state.action}
            navigator={history}
        />
    );
};

export default HistoryRouter;
