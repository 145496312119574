import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import { apiRequest } from "@utils/api";


i18n.use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
        fallbackLng: "en",

        ns: [ "mve", "common" ],
        defaultNS: "mve",

        detection: {
            order:  [ "htmlTag" ],
        },

        backend: {
            loadPath: `/static/locales/{{ns}}/{{lng}}.json`
        },
        
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;


const changeLocale = (locale: string) => new Promise<void>((resolve, reject) => {
    apiRequest("POST", `/locale/set`, { locale })
        .then(() => {
            i18n.changeLanguage(locale);
            resolve();
        })
        .catch(err => {
            console.error(err);
            reject();
        });
});

const currentLocale = () => {
    return i18n.language;
}

export { changeLocale, currentLocale };


// @ts-ignore
window.changeLocale = changeLocale;