import axios, { AxiosRequestConfig } from "axios";
import _ from "lodash";

import config from "@config";
import { getClient } from "@providers/client";
import SocketService from "@services/SocketService";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.common["Accept"]           = "application/json";
axios.defaults.headers.common["Content-Type"]     = "application/json";

export type ApiResponse<T = any> = {
    success: boolean;
    message?: string;
    result?: T;
};

const apiRequest = <T = any>(
    method: "POST" | "GET",
    url: string,
    data?: object,
    extraRequestConfig: AxiosRequestConfig = {}
): Promise<ApiResponse<T>> => new Promise((resolve, reject) => {
    let headers: any = {};

    const socketId = SocketService.getSocketId();
    if (socketId) {
        headers["Socket-Id"] = socketId;
    }

    let requestConfig: AxiosRequestConfig = _.merge(
        {
            method:       method,
            responseType: "json",
            url:          config.API_BASE_URL + url,
            headers:      headers
        },
        extraRequestConfig
    );

    switch (method) {
        case "GET":
            requestConfig.params = data;
            break;

        case "POST":
            requestConfig.data = data;
            break;
    }

    return axios(requestConfig)
        .then(res => {
            resolve(res.data);
        
        }).catch(err => {
            // Unauthenticated
            if (err?.response?.status === 401) {
                const client = getClient();
                client.signOut();
            }
            
            reject(err);
        });
});

export { apiRequest };