const config = {
    APP_BASE_URL: process.env.APP_URL,
    API_BASE_URL: process.env.API_URL,

    CLIP_IMAGE_ACCEPTED_EXTENTIONS: [ ".png", ".jpg", ".jpeg", ".svg" ],

    VIDEO_ACCEPTED_EXTENSIONS: [ ".mp4" ],

    FONTS: [
        { name: "Roboto",    type: "google" },
        { name: "Open Sans", type: "google" },
        { name: "Oswald",    type: "google" },
        { name: "Inter",     type: "google" }
    ],

    MOZAWEB_LANGUAGES: [
        "en", "hu", "de", "fr", "es", "ru", "ar", "zh", "ja", "ko",
        "pt", "it", "ro", "sr", "hr", "sl", "pl", "cs", "sk"
    ]
}

export default config;
