import _ from "lodash";
import LaravelEcho from "laravel-echo";
import Pusher from "pusher-js";

import { getClient } from "@providers/client";

// @ts-ignore-next-line
window.Pusher = Pusher;

let LISTENERS: {
    [event_name: string]: {
        callback: Function;
        once: boolean;
        actionFilter?: string;
    }[]
} = {};


const connect = () => new Promise(resolve => {
    if (window.Echo)
        return resolve(false);

    window.Echo = new LaravelEcho({
        broadcaster: "pusher",
        cluster: process.env.PUSHER_APP_ID,
        key: process.env.PUSHER_APP_KEY,
        forceTLS: process.env.APP_ENV === "production",
        wsHost: process.env.SOCKET_HOST,
        wsPort: process.env.SOCKET_PORT_WS,
        wssPort: process.env.SOCKET_PORT_WSS,
        enabledTransports: ["ws", "wss"],
        disableStats: true
    });

    window.Echo.connector.pusher.connection.bind("connected", (payload) => {
        resolve(true);
    });

    window.Echo.connector.pusher.connection.bind("failed", (payload) => {
        console.error("Failed to connect to socket server.", payload)
        resolve(false);
    });
});


const callListenerCallbacks = (eventName: string, data: any) => {
    if (! LISTENERS[eventName])
        return false;

    let removeAfterCalled = [];

    // call them
    LISTENERS[eventName].forEach(listener => {
        if (listener.actionFilter && listener.actionFilter !== data.action)
            return false;

        const result = listener.callback(data);

        // remove which marked as 'once' or returned false
        if (listener.once || result === false) {
            removeAfterCalled.push(listener);
        }
    });
    
    // remove marked listeners
    LISTENERS[eventName] = LISTENERS[eventName].filter(listener => ! removeAfterCalled.includes(listener));
    
    return true;
};


const listen = (channelName: string, eventName: string, callback: Function, once: boolean = false, actionFilter?: string) => {
    const channel = window.Echo.private(channelName);

    if (! LISTENERS[eventName]) {
        LISTENERS[eventName] = [];

        channel.listen(eventName, (data) => {
            // FILTERS
            if (_.isObject(data.filters)) {
                // Socket ID
                if (data.filters.SocketId && data.filters.SocketId !== getSocketId()) {
                    return false;
                }
            }
            
            callListenerCallbacks(eventName, data);
        });
    }

    LISTENERS[eventName].push({
        callback,
        once,
        actionFilter
    });

    return true;
}

const unlisten = (eventName: string, callback: Function) => {
    LISTENERS[eventName] = LISTENERS[eventName].filter(listener => listener.callback !== callback);
    
    return true;
}


const listenUserEvents = (action: string, callback: Function, once: boolean = false) => {
    const client = getClient();

    if (! client.isSignedIn())
        return false;

    return listen(`user.${client.id}`, "UserEvents", callback, once, action);
}

const unlistenUserEvents = (callback: Function) => {
    return unlisten("UserEvents", callback);
}


const getSocketId = () => {
    return window.Echo?.socketId?.();
}


export default { connect, listen, listenUserEvents, unlistenUserEvents, getSocketId };
