import React from "react";
import { Outlet } from "react-router";
import { Navigate } from "react-router-dom";

import { useClient } from "@providers/client";

const ProtectedRoute = (props: any) => {
    const client = useClient();
    
    {/* Ha meg van adva az 'isLoginState', akkor csak azt veszi figyelembe... ha nem felel meg a feltételnek, akkor a főoldalra dobja */}
    if (typeof props.isLoginState === "boolean") {
        if (client.isSignedIn() === props.isLoginState) {
            return props.element ?? <Outlet />;
        
        } else {
            return <Navigate to="/" />;
        }
    }

    {/* Alapértelmezett component működés... ha nincs bejelentkezve, akkor a loginra dobja */}
    if (! client.isSignedIn()) {
        return <Navigate to="/auth/login" />;
    }
    
    return props.element ?? <Outlet />;
};

export default ProtectedRoute;
