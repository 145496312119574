import React, { createContext, useContext } from "react";

import VideoPlayer from "@models/videoplayer";
import { useForceRerender } from "@utils/component";

let player: VideoPlayer = null;

const VideoPlayerContext = createContext<[VideoPlayer, Function]>([ player, () => {} ]);


const VideoPlayerProvider = ({ children } : any) => {
    const [ forceRerender ] = useForceRerender();

    const setter = (v: VideoPlayer) => {
        player = v;
        forceRerender();
    }

    return (
        <VideoPlayerContext.Provider value={[ player, setter ]}>
            { children }
        </VideoPlayerContext.Provider>
    );
}

export default VideoPlayerProvider;


const useVideoPlayer = (): [VideoPlayer, Function] => {
    return useContext(VideoPlayerContext);
}

export { useVideoPlayer };


const getVideoPlayer = () => player;

export { getVideoPlayer };
