import React, { createContext, useContext } from "react";

import { useForceRerender } from "@utils/component";
import Project from "@models/project";

let project: Project = null;

const ProjectContext = createContext<[Project, Function]>([ project, () => {} ]);


const ProjectProvider = ({ children } : any) => {
    const [ forceRerender ] = useForceRerender();

    const setter = (v: Project) => {
        project = v;
        forceRerender();
    }

    return (
        <ProjectContext.Provider value={[ project, setter ]}>
            { children }
        </ProjectContext.Provider>
    );
}

export default ProjectProvider;


const useProject = (): [Project, Function] => {
    return useContext(ProjectContext);
}

export { useProject };


const getProject = () => project;

export { getProject };
