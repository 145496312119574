import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";


const GlobalLoadingOverlay = () => {
    const [visible, setVisible] = useState(false);
    const [text, setText] = useState("");

    useEffect(() => {
        window.toggleLoadingOverlay = (state?: boolean, text: string = "") => {
            setText(text);

            setVisible(prev => typeof state === "boolean"
                ? state
                : !prev
            );
        };
    }, []);

    return (
        <div
            style={{
                display: visible ? "flex": "none",
                position: "fixed",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                zIndex: 9999,
                background: "#22222288",
                fontWeight: "bold"
            }}
        >
            <Spinner
                color="light"
                style={{
                    height: "4rem",
                    width:  "4rem"
                }}
            />

            { text }
        </div>
    );
}

export default GlobalLoadingOverlay;
