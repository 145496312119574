import React, { Fragment, Suspense } from "react";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import $ from "jquery";

import { createColorPicker } from "./utils/color";

import "@services/SubtitleEditorService";
import SocketService from "@services/SocketService";

import "./i18n";
import AppRouter from "./router";

import GlobalLoadingOverlay from "@components/misc/global_loading_overlay";

import ClientProvider from "@providers/client";
import VideoPlayerProvider from "@providers/videoplayer";
import SubtitleManagerProvider from "@providers/subtitlemanager";
import ProjectProvider from "@providers/project";

SocketService.connect();

const queryClient = new QueryClient();


const App = () => {
    return (
        <Fragment>
            <Suspense fallback="...">
                <QueryClientProvider client={queryClient}>
                    <ClientProvider>
                        <ProjectProvider>
                            <SubtitleManagerProvider>
                                <VideoPlayerProvider>
                                    <Suspense fallback="Loading...">
                                        <AppRouter />
                                    </Suspense>

                                    <Toaster position="bottom-left" />

                                    <GlobalLoadingOverlay />
                                </VideoPlayerProvider>
                            </SubtitleManagerProvider>
                        </ProjectProvider>
                    </ClientProvider>
                </QueryClientProvider>
            </Suspense>
        </Fragment>
    );
}

export default App;


window.IS_EDITOR = true;
window.$ = $;
window.createColorPicker = createColorPicker;
