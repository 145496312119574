import _ from "lodash";

import { getProject } from "@providers/project";


let HISTORY: Command[] = [];
let POSITION: number = 0;


const undo = () => {
    if (! canUndo())
        return false;

    HISTORY[POSITION - 1].undo();
    POSITION -= 1;
}

const redo = () => {
    if (! canRedo())
        return false;

    HISTORY[POSITION].redo();
    POSITION += 1;
}

const canUndo = () => POSITION > 0;

const canRedo = () => POSITION < HISTORY.length;


abstract class Command {
    public target: any;
    public abstract values: any;
    public prevValues: any;
    public grouping = false;
    public groupingKey?: string;
    public skipHistoryEnabled = false;

    constructor(target: any) {
        this.target = target;
    }

    public getPreviousValues() {
        let prevValues: any = {};
        
        _.each(_.keys(this.values), k => {
            prevValues[k] = this.target[k];
        });

        return prevValues;
    }
    
    public sameAs(another: Command) {
        return another?.constructor && another?.constructor === this.constructor
            && this.target === another.target;
    }

    public execute() {
        if (this.skipHistoryEnabled) {
            this.run();
            return;
        }
    
        if (canRedo()) {
            HISTORY = HISTORY.slice(0, POSITION);
        }
    
        let grouped = false;
    
        if (this.grouping) {
            if (this.groupingKey) {
                let foundCmd = _.find(HISTORY, v => v.groupingKey === this.groupingKey);
    
                if (foundCmd) {
                    let index = _.indexOf(HISTORY, foundCmd);
    
                    this.prevValues = foundCmd.prevValues;
                    HISTORY[index] = this;
                    grouped = true;
                }
    
            } else {
                const lastCmd = _.last(HISTORY);
            
                if (lastCmd && this.sameAs(lastCmd)) {
                    let index = _.indexOf(HISTORY, lastCmd);
    
                    this.prevValues = lastCmd.prevValues;
                    HISTORY[index] = this;
                    grouped = true;
                }
            }
        }
    
        if (! grouped) {
            this.prevValues = this.getPreviousValues();
            HISTORY.push(this);
            POSITION += 1;
        }
        
        this.run();
    }

    protected run() {
        this.apply(this.values);
        this.flagUnsavedChanges();
    }
    
    public undo() {
        this.apply(this.prevValues);
        this.flagUnsavedChanges();
    }

    public redo() {
        this.run();
        this.flagUnsavedChanges();
    }
    
    protected abstract apply(values: any): void

    public setGrouping(state: boolean, key?: string) {
        this.grouping = state;

        if (key) {
            this.setGroupingKey(key);
        }
    }
    
    public setGroupingKey(key: string) {
        this.groupingKey = key;
    }
    
    public skipHistory() {
        this.skipHistoryEnabled = true;
    }

    private flagUnsavedChanges() {
        let project = getProject();

        if (project) {
            project.hasUnsavedChanges = true;
        }
    }
}


export default { undo, redo, canUndo, canRedo };

export { Command };
