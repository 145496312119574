import React from "react";
import { createRoot } from "react-dom/client";

import App from "@/ts/app";
import "@scss/index.scss";

const container = document.getElementById("root");
const root      = createRoot(container!);

root.render(<App />);
