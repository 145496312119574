
import { Command } from "../CommandService";
import ClipPropertyChangeCommand from "./clip_property_change";

import Clip from "@models/clip/clip";

interface IValues {
    positionX?: number;
    positionY?: number;
    scaleX?: number;
    scaleY?: number;
    rotation?: number;
}

export default class SubtitleEditCommand extends Command {
    public declare target: Clip;
    public values: IValues;

    constructor(target: Clip, values: IValues) {
        super(target);
        this.values = values;
    }

    public getPreviousValues() {
        let target = this.target as any;

        return {
            positionX: target.position?.x,
            positionY: target.position?.y,
            scaleX:    target.scale?.x,
            scaleY:    target.scale?.y,
            rotation:  target.rotation?.value
        };
    }

    protected apply(values: IValues) {
        let target = this.target as any;

        if (typeof values.positionX === "number" && typeof values.positionY === "number") {
            let cmd = new ClipPropertyChangeCommand(target, target.position, {
                x: Number(values.positionX.toFixed(2)),
                y: Number(values.positionY.toFixed(2))
            });
            cmd.skipHistory();
            cmd.execute();
    
            target.position?.forceRerender?.();
        }

        if (typeof values.scaleX === "number" && typeof values.scaleY === "number") {
            let cmd = new ClipPropertyChangeCommand(target, target.scale, {
                x: Number(values.scaleX.toFixed(2)),
                y: Number(values.scaleY.toFixed(2))
            });
            cmd.skipHistory();
            cmd.execute();
    
            target.scale?.forceRerender?.();
        }

        if (typeof values.rotation === "number") {
            let rotation = values.rotation % 360;

            if (rotation < 0)
                rotation = 360 + rotation;

            let cmd = new ClipPropertyChangeCommand(target, target.rotation, {
                value: Number(rotation.toFixed(2))
            });
            cmd.skipHistory();
            cmd.execute();
            
            target.rotation?.forceRerender?.();
        }
    }
}
