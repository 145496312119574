import _ from "lodash";

import { Command } from "../CommandService";

import OnChangeDecorator from "@decorators/onchange";
import Clip from "@models/clip/clip";
import ClipProperty from "@models/clipproperty";


export default class ClipPropertyChangeCommand extends Command {
    public declare target: Clip;
    protected property: ClipProperty;
    public values: any;

    constructor(target: Clip, property: ClipProperty, values: any) {
        super(target);
        this.values   = values;
        this.property = property;
    }

    public getPreviousValues() {
        const property = this.property as any;

        let prevValues: any = {};
        
        _.each(_.keys(this.values), k => {
            prevValues[k] = property[k];
        });

        return prevValues;
    }

    public sameAs(another: any) {
        return super.sameAs(another)
            && this.property === another.property;
    }
    
    public undo() {
        this.apply(this.prevValues);
        this.property.forceRerender?.();
    }
    
    public redo() {
        this.run();
        this.property.forceRerender?.();
    }

    protected apply(values: any) {
        const property = this.property as any;

        _.each(_.keys(this.values), k => {
            property[k] = values[k];
        });

        OnChangeDecorator.callOnProperty(this.target, property);
    }
}
