import React, { lazy } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { createBrowserHistory } from "history";

import HistoryRouter from "@components/router/historyrouter";
import ProtectedRoute from "@components/router/protectedroute";

const MainPage           = lazy(() => import("@pages/main/main"));
const ProjectPage        = lazy(() => import("@pages/project/project"));
const ProjectFilesPage   = lazy(() => import("@pages/project/files"));
const ProjectQuizzesPage = lazy(() => import("@pages/project/quizzes"));
const AuthLoginPage      = lazy(() => import("@pages/auth/login"));
const AuthFailedPage     = lazy(() => import("@pages/auth/oauth-failed"));
const AuthLogoutPage     = lazy(() => import("@pages/auth/logout"));
const Error404           = lazy(() => import("@pages/errors/404"));

export const browserHistory = createBrowserHistory({ window });

const AppRouter = () => {
    return (
        <HistoryRouter history={browserHistory}>
            <Routes>
                {/* MAIN */}
                <Route path="/" element={<ProtectedRoute />}>
                    <Route path="/" element={<MainPage />} />
                    <Route path="/project/:projectId" element={<ProjectPage />} />
                    <Route path="/project/:projectId/files" element={<ProjectFilesPage />} />
                    <Route path="/project/:projectId/quizzes" element={<ProjectQuizzesPage />} />
                </Route>

                {/* AUTH PAGES */}
                <Route path="/auth" element={<ProtectedRoute isLoginState={false} />}>
                    <Route path="login" element={<AuthLoginPage />} />
                    <Route path="oauth-failed" element={<AuthFailedPage />} />

                    <Route path="" element={<Navigate to="/auth/login" />} />
                </Route>
                <Route path="/auth" element={<ProtectedRoute isLoginState={true} />}>
                    <Route path="logout" element={<AuthLogoutPage />} />
                    
                    <Route path="" element={<Navigate to="/auth/login" />} />
                </Route>

                {/* ERROR PAGES */}
                <Route path="*" element={<Error404 />} />
            </Routes>
        </HistoryRouter>
    );
}

export default AppRouter;
